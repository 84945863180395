.comp-filter-search-bar {
  display: flex;
  height: 30px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px var(--filter-ui-90);
  align-items: center;
  padding-right: 5px;

  >.input {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    color: var(--filter-ui-30);
    font-size: 12px;

    &::placeholder {
      opacity: 100%;
      color: var(--filter-ui-65);
    }
  }

  &:hover {
    box-shadow: inset 0 0 0 1px var(--filter-ui-65);
  }

  &.focus {
    box-shadow: inset 0 0 0 1px var(--filter-ui-30);
  }

}