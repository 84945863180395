.comp-filter-label {
  display: flex;
  align-items: baseline;
  gap: 5px;
  width: 100%;

  >.title {
    font-size: 12px;
    white-space: nowrap;
    color: var(--filter-ui-65);
  }

  >.line {
    display: flex;
    height: 1px;
    width: 100%;
    background-color: var(--filter-ui-90);
  }
}