.comp-filter-collapsable {
  width: 100%;

  >.header {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
  }

  >.body {
    overflow-y: auto;
    max-height: var(--collapsable-max-height);
    width: 100%;
  }

  &.no-max-height>.body {
    overflow-y: initial;
    max-height: auto;
  }
}