.comp-filter-nav-link {
  display: flex;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--filter-ui-93);
  // box-shadow: inset 0 0 0 1px var(--filter-ui-99);
  width: 100%;
  gap: 10px;

  >.bar {
    height: 100%;
    width: 5px;
    min-width: 5px;
    background-color: var(--filter-ui-90);
    border-radius: 5px;
  }

  >.title {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    color: var(--filter-ui-30);
  }

  &:hover {
    // box-shadow: inset 0 0 0 1px var(--filter-ui-90);
    background-color: var(--filter-ui-99);
  }

  &.active {
    box-shadow: inset 0 0 0 1px var(--filter-ui-99);
    background-color: var(--filter-ui-99);

    >.bar {
      background-color: var(--filter-ui-pink);
    }

    >.title {
      font-weight: 700;
    }
  }

  &.clickable {
    cursor: pointer;
  }
}