@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    /* deep-blue */
    --deep-blue-20: hsl(240, 20%, 20%);
    --deep-blue-60: hsl(240, 20%, 60%);
    --deep-blue-80: hsl(240, 20%, 80%);
    --deep-blue-90: hsl(240, 20%, 90%);
    --deep-blue-95: hsl(240, 20%, 95%);
    --deep-blue-98: hsl(240, 20%, 98%);
    --deep-blue-99: hsl(240, 20%, 99%);

    /* pink */
    --pink-20: hsl(320, 100%, 20%);
    --pink-60: hsl(320, 100%, 60%);
    --pink-80: hsl(320, 100%, 80%);
    --pink-90: hsl(320, 100%, 90%);
    --pink-95: hsl(320, 100%, 95%);
    --pink-98: hsl(320, 100%, 98%);
    --pink-99: hsl(320, 100%, 99%);

    /* filter colors */
    --filter-ui-20: hsl(240, 20%, 20%);
    --filter-ui-30: hsl(240, 20%, 30%);
    --filter-ui-40: hsl(240, 20%, 40%);
    --filter-ui-65: hsl(240, 20%, 65%);
    --filter-ui-90: hsl(240, 20%, 90%);
    --filter-ui-93: hsl(240, 20%, 93%);
    --filter-ui-95: hsl(240, 20%, 95%);
    --filter-ui-97: hsl(240, 20%, 97%);
    --filter-ui-99: hsl(240, 20%, 99%);
    --filter-ui-pink-light: hsl(320, 100%, 85%);
    --filter-ui-pink: hsl(320, 100%, 75%);

    --filter-gradient-pink-to-bottom: linear-gradient(to bottom, var(--filter-ui-pink-light), var(--filter-ui-pink));
    --filter-gradient-blue-to-bottom: linear-gradient(to bottom, var(--filter-ui-93), var(--filter-ui-90));

    /* deep blue shadows */
    --shadow-bottom-deep-blue-80: inset 0 -1px 0 0 var(--deep-blue-80);
    --shadow-bottom-deep-blue-20: inset 0 -1px 0 0 var(--deep-blue-20);
    --shadow-bottom-deep-blue-95: inset 0 -1px 0 0 var(--deep-blue-95);

    --shadow-right-deep-blue-80: inset -1px 0 0 0 var(--deep-blue-80);
    --shadow-right-deep-blue-95: inset -1px 0 0 0 var(--deep-blue-95);

    --shadow-left-deep-blue-80: inset 1px 0 0 0 var(--deep-blue-80);
    --shadow-left-deep-blue-95: inset 1px 0 0 0 var(--deep-blue-95);

    --shadow-top-deep-blue-80: inset 0 1px 0 0 var(--deep-blue-80);
    --shadow-top-deep-blue-95: inset 0 1px 0 0 var(--deep-blue-95);

    --shadow-around-deep-blue-80: inset 0 0 0 1px var(--deep-blue-80);
    --shadow-around-deep-blue-20: inset 0 0 0 1px var(--deep-blue-20);
    --shadow-around-deep-blue-95: inset 0 0 0 1px var(--deep-blue-95);
    --shadow-around-deep-blue-99: inset 0 0 0 1px var(--deep-blue-99);

    /* pink shadows */
    --shadow-around-pink-80: inset 0 0 0 1px var(--pink-80);
}

@media (prefers-color-scheme: dark) {
    :root {

        --dark-correction: 10%;
        /* deep-blue for dark mode */
        --deep-blue-20: hsl(240, 20%, calc(100% - 20% + var(--dark-correction)));
        --deep-blue-60: hsl(240, 20%, calc(100% - 60% + var(--dark-correction)));
        --deep-blue-80: hsl(240, 20%, calc(100% - 80% + var(--dark-correction)));
        --deep-blue-90: hsl(240, 20%, calc(100% - 90% + var(--dark-correction)));
        --deep-blue-95: hsl(240, 20%, calc(100% - 95% + var(--dark-correction)));
        --deep-blue-98: hsl(240, 20%, calc(100% - 98% + var(--dark-correction)));
        --deep-blue-99: hsl(240, 20%, calc(100% - 99% + var(--dark-correction)));

        /* pink for dark mode */
        --pink-20: hsl(320, 100%, calc(100% - 20% + 20%));
        --pink-60: hsl(320, 100%, calc(100% - 60% + 20%));
        --pink-80: hsl(320, 100%, calc(100% - 80% + 20%));
        --pink-90: hsl(320, 100%, calc(100% - 90% + 10%));
        --pink-95: hsl(320, 100%, calc(100% - 95% + 10%));
        --pink-98: hsl(320, 100%, calc(100% - 98% + 10%));
        --pink-99: hsl(320, 100%, calc(100% - 99% + 10%));

        /* filter colors */
        // --filter-ui-30: hsl(240, 20%, calc(100% - 30% + 5%));
        // --filter-ui-65: hsl(240, 20%, calc(100% - 65% + 5%));
        // --filter-ui-90: hsl(240, 20%, calc(100% - 90% + 5%));
        // --filter-ui-93: hsl(240, 20%, calc(100% - 99% + 4%));
        // --filter-ui-95: hsl(240, 20%, calc(100% - 97% + 3%));
        // --filter-ui-97: hsl(240, 20%, calc(100% - 95% + 3%));
        // --filter-ui-99: hsl(240, 20%, calc(100% - 93% + 3%));
        // --filter-ui-pink-light: hsl(320, 100%, 85%);
        // --filter-ui-pink: hsl(320, 100%, 75%);
    }
}

/* start: text styles */

.meduim-14 {
    font-size: 14px;
}

.meduim-16 {
    font-size: 16px;
}

/* end: text styles */