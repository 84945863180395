.comp-filter-card {
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 0 1px var(--filter-ui-99);
  border-radius: 10px;
  background-color: var(--filter-ui-97);
  padding: 20px;
  min-width: 240px;
  width: 240px;
  height: fit-content;
  position: relative;

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 100%;

    >.body {
      overflow: auto;
    }
  }

  &.style-special {
    box-shadow: 0 0 20px -10px var(--filter-ui-pink);
  }

  &.style-special::before {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--filter-gradient-pink-to-bottom);
    border-radius: 10px;
  }

  &.style-special::after {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    content: "";
    top: 1px;
    left: 1px;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    background-color: var(--filter-ui-97);
    border-radius: 9px;
  }

  >.header {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 var(--filter-ui-93);
    margin-bottom: 15px;
    z-index: 1;

    >.left {
      display: flex;
      align-items: center;
      gap: 5px;

      >.title {
        font-size: 12px;
        font-weight: 600;
        color: var(--filter-ui-30);

        >.unit {
          font-weight: 400;
          font-size: 12px;
          color: var(--filter-ui-65);
        }
      }

    }

    >.right {
      display: flex;
      gap: 5px;
    }
  }

  >.body {
    z-index: 1;
    height: 100%;
  }
}