.comp-filter-action {
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  color: var(--filter-ui-99);
  border-radius: 5px;
  white-space: nowrap;

  &.variation-pink {
    background: var(--filter-gradient-pink-to-bottom);

    &:hover,
    &:focus {
      box-shadow: 0 0 0 3px var(--filter-ui-99);
    }
  }

  &.variation-blue {
    border: 1px solid var(--filter-ui-90);
    color: var(--filter-ui-30);
    background: var(--filter-gradient-blue-to-bottom);

    &:hover,
    &:focus {
      border: 1px solid var(--filter-ui-65);
    }
  }

  &.clickable {
    cursor: pointer;
  }
}