.comp-filter-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  >.item {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    padding: 0 10px;

    >.title {
      font-size: 12px;
      color: var(--filter-ui-30);
    }
  }
}