.comp-filter-toggle {
  display: flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
  align-items: center;
  cursor: pointer;

  >.container {
    width: 20px;
    height: 10px;
    display: flex;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px var(--filter-ui-90);

    >.circle {
      border-radius: 10px;
      display: flex;
      height: 10px;
      width: 10px;
      margin-left: 0px;
      background-color: var(--filter-ui-99);
      box-shadow: inset 0 0 0 1px var(--filter-ui-90);
    }

  }


  &.active>.container>.circle {
    margin-left: auto;
    box-shadow: inset 0 0 0 1px var(--filter-ui-pink);
  }
}