.comp-filter-input {
  margin-top: auto;
  display: flex;
  height: 30px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px var(--filter-ui-90);
  align-items: center;

  >.icon {
    height: 16px;
    min-height: 16px;
    width: 16px;
    min-width: 16px;
    margin-left: 10px;
    margin-right: -5px;

    >* {
      fill: var(--filter-ui-65);
    }
  }

  >.input {
    width: 100%;
    height: 100%;
    padding-right: 5px;
    padding-left: 10px;
    color: var(--filter-ui-30);
    font-size: 12px;

    &::placeholder {
      opacity: 100%;
      color: var(--filter-ui-65);
    }
  }

  >.buttons {
    display: flex;
    gap: 5px;

    &:has(:first-child) {
      margin-right: 5px;
    }
  }

  &:hover {
    box-shadow: inset 0 0 0 1px var(--filter-ui-65);

    >.icon>* {
      fill: var(--filter-ui-65);
    }
  }

  &:focus-within {
    box-shadow: inset 0 0 0 1px var(--filter-ui-30);

    >.icon>* {
      fill: var(--filter-ui-30);
    }
  }

}