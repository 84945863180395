.comp-filter-loading-card {
  width: 100%;
  height: 100%;
  animation: blink 1500ms infinite linear;
  border: 1px solid var(--filter-ui-90);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  color: var(--filter-ui-65);
}

@keyframes blink {
  0% {
    background-color: var(--filter-ui-93);
  }
  50% {
    background-color: var(--filter-ui-90);
  }
  100% {
    background-color: var(--filter-ui-93);
  }
}