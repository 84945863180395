.comp-filter-folder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  >.header {
    display: flex;
    height: 40px;
    min-height: 40px;
    margin-bottom: -1px;
    z-index: 1;

    >.tab {
      display: flex;
      align-items: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-right: 10px;
      background-color: var(--filter-ui-93);
      box-shadow: inset 0 1px 0 0 var(--filter-ui-99),
        inset 1px 0 0 0 var(--filter-ui-99),
        inset -1px 0 0 0 var(--filter-ui-99),
        inset 0 -1px 0 0 var(--filter-ui-99);
      // min-width: 260px;
      width: 260px;
      margin-right: -1px;
      
      &:last-child {
        margin-right: 0;
      }

      >.label {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: var(--filter-ui-30);
        width: 100%;
        height: 100%;
        padding-left: 15px;
        cursor: pointer;
      }

      &.selected {
        background-color: var(--filter-ui-95);
        box-shadow: inset 0 1px 0 0 var(--filter-ui-99),
          inset 1px 0 0 0 var(--filter-ui-99),
          inset -1px 0 0 0 var(--filter-ui-99);
          z-index: 1;
      }
    }
  }

  >.body {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: 0;
    padding: 9px;
    background-color: var(--filter-ui-95);
    border: 1px solid var(--filter-ui-99);
    overflow: auto;
  }
  &.no-border-radius-top-right>.body {
    border-top-right-radius: 0;
  }
}