.comp-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color: var(--filter-ui-99);
  cursor: pointer;

  &.no-radius-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.no-radius-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:hover, &:focus {
    box-shadow: inset 0 0 0 1px var(--filter-ui-90);
  }

  &:active {
    box-shadow: inset 0 0 0 1px var(--filter-ui-65);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
  }


  >.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--filter-ui-65);
  }
}