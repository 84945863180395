.comp-filter-table {
  border: 1px solid var(--filter-ui-99);
  border-radius: 10px;
  background-color: var(--filter-ui-95);
  border-spacing: 0;
  overflow: hidden;
  width: 100%;

  >thead {

    >tr {
      >th {
        >.header {
          background-color: var(--filter-ui-97);
          height: 30px;
          padding: 10px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid var(--filter-ui-90);
          font-size: 14px;
          font-weight: 600;
        }

        &:not(:last-child)>.header {
          border-right: 1px solid var(--filter-ui-90);
        }
      }
    }
  }

  >tbody {
    >tr {

      >td {

        >.cell {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 10px;
          height: 30px;
          white-space: nowrap;
        }

        &:not(:last-child)>.cell {
          border-right: 1px solid var(--filter-ui-90);
        }
      }

      &:not(:last-child)>td>.cell {
        border-bottom: 1px solid var(--filter-ui-99);
      }

      &:nth-child(even)>td>.cell {
        background-color: var(--filter-ui-97);
      }
    }
  }
}