.comp-filter-select-option {
  display: flex;
  align-items: center;

  >.container {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    // height: 30px;
    padding: 5px 10px;
    border-radius: 5px;

    >.title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 12px;
      color: var(--filter-ui-30);

    }

  }

  >.arrow {
    margin-left: -1px;
    height: 10px;
    width: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    >* {
      height: 5px;
      width: 10px;
      min-height: 5px;
      min-width: 10px;
      transform: rotateZ(90deg);
    }
  }

  &.selected:hover>.container {
    box-shadow: inset 0 0 0 1px var(--filter-ui-90);
  }

  &.active {

    >.container {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: var(--filter-ui-99);
      box-shadow: inset -1px 0 0 0 var(--filter-ui-90);
    }

    >.arrow {
      opacity: 1;
    }
  }
}