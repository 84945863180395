.comp-filter-form-message {
  font-size: 12px;
  color: var(--filter-ui-30);
  padding: 5px;
  background-color: var(--filter-ui-97);
  border: 1px solid var(--filter-ui-93);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;

  >.icon {
    flex-shrink: 0;
  }
}