.comp-filter-input-upload {
  display: flex;
  height: 30px;
  min-height: 30px;
  width: 100%;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  border: 1px solid var(--filter-ui-90);
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  
  &:hover {
    border: 1px solid var(--filter-ui-65);
  }

  >.input {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  >.placeholder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    height: 100%;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }

  >.action {
    display: flex;
    height: 100%;
    width: fit-content;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: var(--filter-ui-99);
    white-space: nowrap;
  }
}