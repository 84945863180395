.comp-filter-field-action {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid var(--filter-ui-93);
  border-radius: 5px;
  padding: 10px;
  background-color: var(--filter-ui-99);

  &.is-clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid var(--filter-ui-90);

      >.head {
        >.action {
          display: flex;
          align-items: center;
          gap: 3px;
        }
      }
    }
  }

  >.head {
    display: flex;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--filter-ui-95);

    >.name,
    >.action {
      border: 1px solid var(--filter-ui-93);
      border-radius: 5px;
      background-color: var(--filter-ui-97);
      font-size: 12px;
      padding: 3px 5px;
    }

    >.name {
      margin-right: auto;
    }

    >.action {
      display: none;
    }

    >.feedback {
      border: 1px solid var(--filter-ui-pink);
      border-radius: 5px;
      background-color: var(--filter-ui-99);
      font-size: 12px;
      padding: 3px 5px;
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }

  >.value {
    font-size: 12px;
    font-weight: 500;
  }
}