.comp-filter-order-card {
  border: 1px solid var(--filter-ui-99);
  background-color: var(--filter-ui-97);
  border-radius: 10px;
  padding: 20px;

  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }

  >.head {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--filter-ui-93);

    >.title {
      font-weight: 600;
      overflow: hidden;
    }

    >.tags {
      display: flex;
      gap: 5px;

      >.tag {
        background-color: var(--filter-ui-93);
        border: 1px solid var(--filter-ui-90);
        border-radius: 5px;
        padding: 3px 5px;

        &.special {
          background-color: var(--filter-ui-99);
          border: 1px solid var(--filter-ui-pink);
          color: var(--filter-ui-pink);
        }
      }
    }

  }

  >.body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    >.actions {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
    }

    >.details {
      display: grid;
      grid-template-columns: auto 1fr;
      width: 100%;
      padding-bottom: 5px;
      row-gap: 3px;
      column-gap: 5px;

      >.detail {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 3;
        height: 24px;
        width: 100%;
        border: solid 1px var(--filter-ui-93);
        border-radius: 5px;
        overflow: hidden;

        >.name {
          display: flex;
          align-items: center;
          font-size: 12px;
          padding: 0 5px;
          background-color: var(--filter-ui-95);
          height: 100%;
          white-space: nowrap;
        }

        >.value {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          height: 100%;
          padding: 0 5px;
          flex-grow: 1;
          justify-content: flex-end;
          white-space: nowrap;
          overflow: hidden;
          // background-color: var(--filter-ui-99);
        }
      }
    }
  }
}