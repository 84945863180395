.comp-filter-radio-option {
  display: flex;
  height: 30px;
  min-height: 30px;
  width: 100%;
  align-items: center;

  >.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 5px;
    padding-left: 10px;
    border-radius: 5px;

    >.title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-weight: 400;
      color: var(--filter-ui-30);
      cursor: pointer;
    }

  }

  >.arrow {
    opacity: 0;
    height: 10px;
    width: 5px;
    min-height: 10px;
    min-width: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;

    >* {
      height: 5px;
      width: 10px;
      min-height: 5px;
      min-width: 10px;
      transform: rotateZ(90deg);
    }
  }


  &.focus {
    >.container {
      background-color: var(--filter-ui-99);
    }
  }

  &.parent {

    &.focus {
      >.container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: inset -1px 0 0 0 var(--filter-ui-90);
      }

      >.arrow {
        opacity: 1;
      }
    }

    &:hover {
      >.container {
        box-shadow: inset 0 0 0 1px var(--filter-ui-90);
      }
    }
  }

  &:hover {
    >.container {
      box-shadow: inset 0 0 0 1px var(--filter-ui-90);
    }
  }
}