.comp-filter-tag {
  display: flex;
  height: 30px;
  min-height: 30px;
  width: 100%;
  align-items: center;

  >.title {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    width: 100%;
    background-color: var(--filter-ui-99);
    box-shadow: inset -1px 0 0 0 var(--filter-ui-90);
    font-size: 12px;
    font-weight: 500;
    color: var(--filter-ui-pink);
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  >.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5px;
    height: 10px;
    min-width: 5px;
    min-height: 10px;
    margin-left: -1px;

    >* {
      height: 5px;
      width: 10px;
      min-height: 5px;
      min-width: 10px;
      transform: rotateZ(90deg);
    }
  }
}