.comp-filter-radio-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 15px;
  min-width: 15px;
  cursor: pointer;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    min-width: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px var(--filter-ui-90);

    >.circle {
      border-radius: 50%;
      height: 6px;
      width: 6px;
      box-shadow: inset 0 0 0 1px var(--filter-ui-90);
      background-color: var(--filter-ui-99);
    }
  }

  &.active>.container>.circle {
    height: 8px;
    width: 8px;
    box-shadow: inset 0 0 0 1px var(--filter-ui-pink);
  }
}