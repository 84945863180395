.comp-filter-number-input {
  display: flex;
  box-shadow: inset 0 0 0 1px var(--filter-ui-90);
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding-right: 5px;
  align-items: center;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--filter-ui-65);
  }

  >.input {
    height: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 5px;
    color: var(--filter-ui-30);
    font-size: 12px;
    
    &::placeholder {
      opacity: 100%;
      color: var(--filter-ui-65);
    }
  }
  
  &.focus {
    box-shadow: inset 0 0 0 1px var(--filter-ui-30);
  }

  >.buttons {
    display: flex;

    >.separator {
      width: 1px;
      min-width: 1px;
      height: 20px;
      background-color: var(--filter-ui-90);
      margin-right: -1px;
      margin-left: -1px;
      z-index: 1;
    }
  }
}