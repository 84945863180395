* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  border-radius: 0px;
  text-decoration: none;
  list-style: none;
  text-indent: 0px;
  background-color: transparent;
  color: var(--filter-ui-30);
  font-family: "Raleway", sans-serif;

  // /* Hide scrollbar for IE, Edge and Firefox */
  // -ms-overflow-style: none;
  // scrollbar-width: none;

  // /* Hide scrollbar for Chrome, Safari and Opera */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

/* start: hide input controls */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* end: hide input controls */

/* start: custom scrollbar */

::-webkit-scrollbar-corner { background: transparent; }

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 4px solid var(--deep-blue-99);
  border-radius: 8px;
  background: var(--shadow-around-deep-blue-99);
  box-shadow: var(--shadow-around-deep-blue-80);
}

::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  border: 4px solid var(--deep-blue-99);
  background: var(--deep-blue-95);
}

::-webkit-scrollbar-thumb:active {
  background-clip: padding-box;
  border: 4px solid var(--deep-blue-99);
  background: var(--deep-blue-95);
  box-shadow: var(--shadow-around-deep-blue-20);
}

/* end: custom scrollbar */

/* start: filter scrollbar */

.hxp-scrollbar-filter {
  --scrollbar-filter-bg: var(--filter-ui-97);
}

.hxp-scrollbar-filter ::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.hxp-scrollbar-filter ::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: 9.5px solid var(--scrollbar-filter-bg);
  border-radius: 10px;
  background: var(--filter-ui-90);
  // background: transparent;
}

.hxp-scrollbar-filter ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 6px solid var(--scrollbar-filter-bg);
  border-radius: 10px;
  background: var(--filter-ui-99);
  box-shadow: inset 0 0 0 1px var(--filter-ui-90);
}

.hxp-scrollbar-filter ::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  background: var(--filter-ui-99);
  box-shadow: inset 0 0 0 1px var(--filter-ui-65);
}

.hxp-scrollbar-filter ::-webkit-scrollbar-thumb:active {
  background-clip: padding-box;
  background: var(--filter-ui-99);
  box-shadow: inset 0 0 0 1px var(--filter-ui-30);
}

/* end: filter scrollbar */

/* start: reset elements */

body {
  width: 100%;
  height: 100vh;
  background-color: var(--deep-blue-99);
}

#root {
  height: 100%;
  width: 100%;
}

/* start: reset elements */